/* Copyright 2013 - 2024 Waiterio LLC */
import {
  setAccessTokenForDododentistAnalyticsClient,
  setAccessTokenCallbackForDododentistAnalyticsClient,
} from '../client/accessToken.js'
import { setRefreshTokenForDododentistAnalyticsClient } from '../client/refreshToken.js'
import getAccessToken from './getAccessToken.js'
import getRefreshToken from './getRefreshToken.js'
import setAccessToken from './setAccessToken.js'

export default async function rehydrateSession() {
  const accessToken = getAccessToken()
  const refreshToken = getRefreshToken()

  setAccessTokenForDododentistAnalyticsClient(accessToken)
  setRefreshTokenForDododentistAnalyticsClient(refreshToken)
  setAccessTokenCallbackForDododentistAnalyticsClient(setAccessToken)
}
