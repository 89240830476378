/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/react'
import getAppUrl from '@dododentist/shared/getAppUrl.js'
import { useTranslation } from '@multilocale/react/index.js'
import Button from '@stiloso/components/Button.js'
import Input from '@stiloso/components/Input.js'
import Modal from '@stiloso/components/Modal.js'
import postImpersonate from '../postImpersonate.js'

const ImpersonateDialog = props => {
  let emailToImpersonate = props.email
  const { t } = useTranslation()
  let [email, setEmail] = useState('')
  let [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  let [error, setError] = useState()

  const changeEmail = event => {
    setEmail(event.target.value)
  }

  const changePassword = event => {
    setPassword(event.target.value)
  }

  const impersonate = async event => {
    event?.preventDefault?.()
    try {
      setError(null)
      setLoading(true)
      let result = await postImpersonate({
        email,
        password,
        emailToImpersonate,
      })
      setLoading(false)
      window.open(
        `${getAppUrl()}/logout?email=${result.email}&password=${result.password}`,
        '_blank',
      )
    } catch (error) {
      setLoading(false)
      setError(error)
    }
  }

  return (
    <Modal close={props.close}>
      <form css={{ padding: 16 }} onSubmit={impersonate}>
        <h2>{t('Login with your credentials')}</h2>
        <Input
          type="email"
          name="email"
          label={t('email')}
          autoComplete="email"
          autoCapitalize="none"
          autoCorrect="off"
          value={email || ''}
          onChange={changeEmail}
          required
          autoFocus
        />
        <Input
          type="password"
          name="password"
          label={t('password')}
          autoComplete="current-password"
          autoCapitalize="none"
          autoCorrect="off"
          value={password || ''}
          onChange={changePassword}
          required
        />
        <div css={{ height: 16 }} />
        <Button type="submit" fullWidth isLoading={loading}>
          {t('Impersonate') + ' ' + emailToImpersonate}
        </Button>
      </form>
      {error && (
        <div
          css={{
            textAlign: 'center',
            lineHeight: '24px',
            marginTop: 32,
            color: 'var(--color-red)',
          }}
        >
          {t(error)}
        </div>
      )}
    </Modal>
  )
}

export default ImpersonateDialog
