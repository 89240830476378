/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { cache, use, Suspense } from 'react'
import { css, jsx } from '@emotion/react'
import { format } from 'date-fns'
import getCountryNameByCode from '@monorepo/shared/getCountryNameByCode.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Spinner from '@stiloso/components/Spinner.js'
import table from '@stiloso/styles/table.js'
import get from '../client/get.js'
import Layout from '../components/Layout.js'

export const paths = ['/clinics']

const fetchClinicsPageData = cache(() => get({ url: '/api/pages/clinics' }))

const ClinicsPage = () => {
  let clinics = use(fetchClinicsPageData())

  return (
    <div css={{ padding: 16 }}>
      <h1>{clinics.length} Clinics</h1>
      <table css={table}>
        <thead>
          <tr>
            <th>name</th>
            <th>creation</th>
            <th>country</th>
            <th>phone</th>
          </tr>
        </thead>
        <tbody>
          {clinics?.map(clinic => (
            <tr
              key={clinic._id}
              onClick={() => window.open(`/clinics/${clinic._id}`)}
            >
              <td>{clinic.name || clinic._id}</td>
              <td>{format(clinic.creationTime, 'd MMMM yyyy')}</td>
              <td>{getCountryNameByCode(clinic.countryCode)}</td>
              <td>{clinic.phone}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const ClinicsPageWrapper = () => (
  <Layout title="Clinics">
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <ClinicsPage />
      </Suspense>
    </ErrorBoundary>
  </Layout>
)

export default ClinicsPageWrapper
