/* Copyright 2013 - 2024 Waiterio LLC */
import getAccessToken from './getAccessToken.js'
import clearSession from './clearSession.js'

export default function isLoggedInSession() {
  let isLoggedIn = false

  const accessToken = getAccessToken()

  const validAccessToken = accessToken && accessToken.length > 0

  if (validAccessToken) {
    isLoggedIn = true
  }

  if (!isLoggedIn) {
    clearSession()
  }

  return isLoggedIn
}
