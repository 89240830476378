/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import IconAlarm from '@stiloso/icons/IconAlarm.js'
import IconRssFeed from '@stiloso/icons/IconRssFeed.js'
import IconUsers from '@stiloso/icons/IconUsers.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import dLgNone from '@stiloso/styles/bootstrap/dLgNone.js'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import LayoutDesktop from './LayoutDesktop.js'
import LayoutSmartphone from './LayoutSmartphone.js'

const useMainPages = title => {
  const { t } = useTranslation()
  let pathname = ''

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
  }

  let pages = [
    {
      label: 'Appointments',
      to: '/appointments',
      Icon: IconAlarm,
    },
    {
      label: 'Clinics',
      to: '/clinics',
      Icon: IconRssFeed,
    },
    {
      label: 'Users',
      to: '/users',
      Icon: IconUsers,
    },
  ]
    .filter(_ => _)
    .map(page => ({
      ...page,
      label: t(page.label),
      selected: pathname.endsWith(page.to) || title === t(page.label),
    }))

  return pages
}

const Layout = props => {
  let { children, main, title } = props
  const pages = useMainPages(title)

  if (typeof main === 'undefined') {
    main = true
  }

  let subprops = {
    ...props,
    children: undefined,
    main,
    pages,
  }

  return (
    <>
      <div css={dLgNone}>
        <LayoutSmartphone {...subprops}>{children}</LayoutSmartphone>
      </div>
      <div css={[dNone, dLgBlock]}>
        <LayoutDesktop {...subprops}>{children}</LayoutDesktop>
      </div>
    </>
  )
}

export default Layout
