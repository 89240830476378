/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect } from 'react'
import { jsx } from '@emotion/react'
import { useLocation } from 'react-router-dom'
import getBaseUrl from '../getBaseUrl.js'
import clearSession from '../session/clearSession.js'

export const paths = ['/logout']

const LogoutPage = () => {
  let location = useLocation()
  let urlSearchParams = new URLSearchParams(location.search)
  let querystring = '?' + urlSearchParams.toString()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)

      clearSession()

      window.location.href = `${getBaseUrl()}/login${querystring}`
    }
  })

  return (
    <div
      css={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h1>Logout...</h1>
    </div>
  )
}

export default LogoutPage
