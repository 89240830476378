/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import Drawer from '@stiloso/components/Drawer.js'
import DrawerButton from '@stiloso/components/DrawerButton.js'
import DrawerTitle from '@stiloso/components/DrawerTitle.js'
import IconPowerOff from '@stiloso/icons/IconPowerOff.js'

const LayoutDesktop = ({ children, pages }) => {
  const { t } = useTranslation()

  return (
    <div
      css={{
        display: 'grid',
        height: '100%',
        gridTemplateColumns: '200px auto',
        gridTemplateAreas: `
          'drawer children'
        `,
      }}
    >
      <Drawer>
        <DrawerTitle>{t('Dodo Dentist')}</DrawerTitle>
        {pages.map(props => (
          <DrawerButton key={props.to} {...props} />
        ))}
        <div css={{ flexGrow: 1 }} />
        <DrawerButton
          key="/logout"
          label={t('Logout')}
          to="/logout"
          Icon={IconPowerOff}
        />
      </Drawer>
      <div
        css={{
          gridArea: 'children',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          width: '100%',
          background: 'var(--color-background)',
          overflowX: 'hidden',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default LayoutDesktop
