/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { cache, use, useState } from 'react'
import { css, jsx } from '@emotion/react'
import { Bar } from 'react-chartjs-2'
import { useNavigate, useParams } from 'react-router-dom'
import {
  addDays,
  differenceInDays,
  getTime,
  format,
  startOfDay,
} from 'date-fns'
import 'chartjs-plugin-datalabels'
import countries from '@monorepo/countries/index.js'
import getLanguageNameByCode from '@monorepo/shared/getLanguageNameByCode.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import get from '../client/get.js'
import Layout from '../components/Layout.js'
import ImpersonateDialog from '../dialogs/ImpersonateDialog.js'

let clickableCard = css([
  clickable,
  card,
  {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    marginRight: 16,
  },
])

export const paths = ['/clinics/:clinicId']

const fetchClinicPageData = cache(clinicId =>
  get({ url: `/api/pages/clinics/${clinicId}` }),
)

const ClinicPage = () => {
  const navigate = useNavigate()
  const { clinicId } = useParams()
  let [impersonateDialog, setImpersonateDialog] = useState(false)
  let data = use(fetchClinicPageData(clinicId))
  // console.log({ data })
  let {
    appointments,
    appointmentsCount,
    patientsCount,
    proceduresCount,
    treatmentsCount,
    clinic,
    users,
  } = data || {}

  console.log({ appointments })

  clinic.language ||= users.find(({ language }) => language)?.language || ''
  clinic.phone ||= users.find(({ phone }) => phone)?.phone || ''

  let defaultDays = 30

  let startTime = Math.min(
    ...appointments
      .map(appointment => getTime(appointment.creationTime))
      .concat([getTime(startOfDay(addDays(new Date(), -defaultDays)))]),
  )

  startTime &&= new Date(startTime)

  const days = differenceInDays(new Date(), startTime) + 1

  const day2appointmentsCount = [...Array(days || defaultDays).keys()].reduce(
    (day2mealsCount, i) =>
      Object.assign(day2mealsCount, {
        [format(addDays(startTime, i), 'yyyy-MM-dd')]: 0,
      }),
    {},
  )

  appointments.forEach(appointment => {
    const day = format(appointment.creationTime, 'yyyy-MM-dd')

    day2appointmentsCount[day] += 1
  })

  let labels = Object.keys(day2appointmentsCount)
  let dataAppointments = Object.keys(day2appointmentsCount).map(
    day => day2appointmentsCount[day],
  )

  let chart = {
    data: {
      labels,
      datasets: [
        {
          label: 'appointments',
          data: dataAppointments,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
        },
      ],
    },
    options: {
      layout: {
        padding: {
          top: 20,
        },
      },
      plugins: {
        datalabels: {
          color: 'rgba(98, 98, 98, 1)',
          align: 'top',
          anchor: 'end',
          display: context => context.dataset.data[context.dataIndex] !== 0,
        },
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'D MMM',
              },
              unitStepSize: 1,
            },
            offset: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: false,
              max: Math.max(...dataAppointments),
            },
          },
        ],
      },
    },
  }

  let title = clinic.name || `Clinic ${clinic._id}`
  const onClickTitle = () => {
    navigate('/clinics')
  }

  const showImpersonateDialog = user => {
    setImpersonateDialog({ email: user.email })
  }

  return (
    <Layout back title={title} main={false} onClickTitle={onClickTitle}>
      <div css={{ padding: 16 }}>
        <h1>{clinic.name || clinic._id}</h1>

        <div css={{ padding: 16, display: 'flex', gap: 16 }}>
          <div>{format(clinic.creationTime, 'd MMMM yyyy')}</div>
          {clinic.phone && (
            <div>
              phone{' '}
              <a
                href={`https://web.whatsapp.com/send?phone=${clinic.phone?.replace('+', '')}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {clinic.phone}
              </a>
            </div>
          )}
          {clinic.language && (
            <div>language {getLanguageNameByCode(clinic.language)}</div>
          )}
          {clinic.countryCode && (
            <div>
              country{' '}
              {countries[clinic.countryCode]?.countryName || clinic.countryCode}
            </div>
          )}
        </div>

        <div css={{ padding: 16, paddingBottom: 0 }}>
          <h2>{appointmentsCount} appointments</h2>
        </div>

        <Bar height={window.innerWidth < 576 ? 200 : 80} {...chart} />

        <div css={{ padding: 16 }}>
          <h2 css={{ paddingBottom: 8 }}>{patientsCount} patients</h2>
        </div>
        <div css={{ padding: 16 }}>
          <h2 css={{ paddingBottom: 8 }}>{proceduresCount} procedures</h2>
        </div>
        <div css={{ padding: 16 }}>
          <h2 css={{ paddingBottom: 8 }}>{treatmentsCount} treatments</h2>
        </div>
        <div css={{ padding: 16 }}>
          <h2 css={{ paddingBottom: 8 }}>{users.length} users</h2>
          <div css={{ display: 'flex' }}>
            {users.map(user => (
              <div
                key={user._id}
                css={[
                  card,
                  { padding: 0, display: 'flex', flexDirection: 'column' },
                ]}
              >
                <div
                  css={[clickable, { padding: 16 }]}
                  onClick={() => navigate(`/users/${user._id}`)}
                >
                  {user.firstName} {user.lastName}
                  <div>{getLanguageNameByCode(user.language)}</div>
                  <div>{user?.roles.join(', ')}</div>
                </div>
                {user.email && (
                  <a
                    css={[
                      clickable,
                      {
                        borderTop: '1px solid var(--color-background)',
                        padding: 8,
                        textAlign: 'center',
                      },
                    ]}
                    href={`mailto:${user.email}`}
                  >
                    {user.email}
                  </a>
                )}
                {user.phone && (
                  <a
                    css={[
                      clickable,
                      {
                        borderTop: '1px solid var(--color-background)',
                        padding: 8,
                        textAlign: 'center',
                      },
                    ]}
                    href={`https://web.whatsapp.com/send?phone=${user.phone?.replace('+', '')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {user.phone}
                  </a>
                )}
                <div
                  css={[
                    clickable,
                    {
                      borderTop: '1px solid var(--color-background)',
                      padding: 8,
                      textAlign: 'center',
                      textTransform: 'uppercase',
                    },
                  ]}
                  onClick={() => showImpersonateDialog(user)}
                >
                  Impersonate
                </div>
              </div>
            ))}
          </div>
        </div>
        <div css={{ padding: 16 }}>
          <h2 css={{ paddingBottom: 8 }}>organization</h2>
          <div css={{ display: 'flex' }}>
            <div
              css={clickableCard}
              onClick={() =>
                navigate(`/organizations/${clinic.organizationId}`)
              }
            >
              <div>organization {clinic.organizationId}</div>
            </div>
          </div>
        </div>
      </div>
      {impersonateDialog && (
        <ImpersonateDialog
          {...impersonateDialog}
          close={() => {
            setImpersonateDialog(null)
          }}
        />
      )}
    </Layout>
  )
}

export default ClinicPage
