/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import ActionBar from '@stiloso/components/ActionBar.js'
import ActionBarButton from '@stiloso/components/ActionBarButton.js'
import ActionBarTitle from '@stiloso/components/ActionBarTitle.js'
import Tab from '@stiloso/components/Tab.js'
import Tabs from '@stiloso/components/Tabs.js'
import IconBack from '@stiloso/icons/IconBack.js'

const LayoutSmartphone = ({
  actions,
  back,
  children,
  main,
  onClickTitle,
  pages,
  title,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div
      css={{
        display: 'grid',
        height: '100%',
        gridTemplateRows: main ? '112px auto' : '64px auto',
        gridTemplateAreas: `
          'actionbar'
          'children'
        `,
      }}
    >
      <div css={{ gridArea: 'actionbar' }}>
        <ActionBar>
          <ActionBarTitle title={title} onClick={onClickTitle}>
            {back && <IconBack />}
            {main ? t('Dodo Dentist') : title}
          </ActionBarTitle>
          <div css={{ flex: 1 }} />
          {actions?.map(action => (
            <ActionBarButton key={action} action={action} />
          ))}
        </ActionBar>
        {main && (
          <div css={{ position: 'fixed', top: 64, width: '100%' }}>
            <Tabs>
              {pages.map(props => (
                <Tab
                  key={props.to}
                  onClick={() => {
                    navigate(props.to)
                  }}
                  selected={props.selected}
                  totalTabs={pages.length}
                >
                  {props.label}
                </Tab>
              ))}
            </Tabs>
          </div>
        )}
      </div>
      <div
        css={{
          gridArea: 'children',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          background: 'var(--color-background)',
          overflowX: 'hidden',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default LayoutSmartphone
