/* Copyright 2013 - 2024 Waiterio LLC */
import {
  setAccessTokenForDododentistAnalyticsClient,
  setAccessTokenCallbackForDododentistAnalyticsClient,
} from '../client/accessToken.js'
import { setRefreshTokenForDododentistAnalyticsClient } from '../client/refreshToken.js'
import setAccessToken from './setAccessToken.js'
import setRefreshToken from './setRefreshToken.js'
import identify from '../identify.js'

export default async function storeNewSession({ accessToken, refreshToken }) {
  try {
    setAccessToken(accessToken)
    setRefreshToken(refreshToken)

    setAccessTokenForDododentistAnalyticsClient(accessToken, setAccessToken)
    setRefreshTokenForDododentistAnalyticsClient(refreshToken, setRefreshToken)
    setAccessTokenCallbackForDododentistAnalyticsClient(setAccessToken)
    identify()

    return true
  } catch (error) {
    console.error('error', error)
    throw error
  }
}
