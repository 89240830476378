/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import LoginPage from '@stiloso/pages/LoginPage.js'
import { useNavigate } from 'react-router-dom'
import postLogin from '../postLogin.js'
import storeNewSession from '../session/storeNewSession.js'

export const paths = ['/login']

const LoginPageWrapper = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const login = async ({ email, password }) => {
    const loginResponse = await postLogin(email, password)
    await storeNewSession(loginResponse)

    navigate('/users')
  }

  let brand = (
    <div css={{ color: 'var(--color-primary)', fontSize: 36 }}>
      {t('Dododentist')}
    </div>
  )

  return <LoginPage brand={brand} login={login} />
}

export default LoginPageWrapper
