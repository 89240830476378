/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { cache, use, useState, Suspense } from 'react'
import { css, jsx } from '@emotion/react'
import {
  addDays,
  format,
  formatISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import getCountryNameByCode from '@monorepo/shared/getCountryNameByCode.js'
import Dropdown from '@stiloso/components/Dropdown.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Spinner from '@stiloso/components/Spinner.js'
import table from '@stiloso/styles/table.js'
import get from '../client/get.js'
import Layout from '../components/Layout.js'

export const paths = ['/appointments']

const fetchAppointmentsPageData = cache(startTime =>
  get({ url: `/api/pages/appointments?startTime=${startTime || ''}` }),
)

const now = new Date()

const daysRange = [
  { label: 'All', value: null },
  { label: 'Last 365 days', value: formatISO(addDays(now, -365)) },
  { label: 'Last 30 days', value: formatISO(addDays(now, -30)) },
  { label: 'Last 7 days', value: formatISO(addDays(now, -7)) },
  { label: 'This month', value: formatISO(startOfMonth(now)) },
  { label: 'This week', value: formatISO(startOfWeek(now, -1)) },
  { label: 'Yesterday', value: formatISO(startOfDay(addDays(now, -1))) },
  { label: 'Today', value: formatISO(startOfDay(now)) },
]

const daysRangeLabels = daysRange.map(({ label }) => label)
const daysRangeValues = daysRange.map(({ value }) => value)

const AppointmentsPage = () => {
  let [currentDaysRange, setCurrentDaysRange] = useState(
    formatISO(addDays(now, -7)),
  )
  let clinics = use(fetchAppointmentsPageData(currentDaysRange))

  return (
    <div css={{ padding: 16 }}>
      <div css={{ display: 'flex' }}>
        <h1 css={{ flexGrow: 1 }}>{clinics.length} clinics</h1>
        <div css={{ width: 240 }}>
          <Dropdown
            labels={daysRangeLabels}
            values={daysRangeValues}
            currentValue={currentDaysRange}
            onSelect={setCurrentDaysRange}
          />
        </div>
      </div>
      <Dropdown />
      <table css={table}>
        <thead>
          <tr>
            <th>name</th>
            <th>signed up</th>
            <th>country</th>
            <th>appointments</th>
          </tr>
        </thead>
        <tbody>
          {clinics?.map(clinic => (
            <tr
              key={clinic._id}
              onClick={() => window.open(`/clinics/${clinic._id}`)}
            >
              <td>{clinic.name || clinic._id}</td>
              <td>{format(clinic.creationTime, 'd MMMM yyyy')}</td>
              <th>{getCountryNameByCode(clinic.countryCode)}</th>
              <td>{clinic.appointmentsCount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const AppointmentsPageWrapper = () => (
  <Layout title="Appointments">
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <AppointmentsPage />
      </Suspense>
    </ErrorBoundary>
  </Layout>
)

export default AppointmentsPageWrapper
