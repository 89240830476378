/* Copyright 2013 - 2024 Waiterio LLC */
import countries from '@monorepo/shared/countries.js'

export default function getCountryNameByCode(countryCode) {
  countryCode = countryCode && countryCode.toUpperCase()

  if (countries[countryCode]) {
    return countries[countryCode]
  } else {
    return countryCode
  }
}
