/* Copyright 2013 - 2024 Waiterio LLC */
import localStorage from 'localStorage'
import IS_BROWSER from '@monorepo/env/IS_BROWSER.js'
import {
  setAccessTokenForDododentistAnalyticsClient,
  setAccessTokenCallbackForDododentistAnalyticsClient,
} from '../client/accessToken.js'
import { setRefreshTokenForDododentistAnalyticsClient } from '../client/refreshToken.js'

export default function clearSession() {
  if (IS_BROWSER) {
    localStorage.clear()

    setAccessTokenCallbackForDododentistAnalyticsClient(null)

    setAccessTokenForDododentistAnalyticsClient(null)
    setRefreshTokenForDododentistAnalyticsClient(null)
  }
}
