/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

export default css({
  borderCollapse: 'collapse',
  boxSizing: 'border-box',
  width: '100%',
  textAlign: 'right',
  padding: '0 16px',
  '& > thead > tr': {
    fontSize: '18px',
    fontWeight: 'normal',
    height: '48px',
    cursor: 'help',
    '> th': {
      lineHeight: '48px',
      ':first-of-type': {
        paddingLeft: '24px',
        textAlign: 'left',
      },
      ':last-child': {
        paddingRight: '24px',
      },
    },
  },
  '& > tbody > tr': {
    border: 'solid',
    borderWidth: '1px 0',
    cursor: 'pointer',
    height: '48px',
    ':last-child': {
      borderBottom: 'none',
    },
    ':hover, :focus-visible': {
      backgroundColor: 'var(--color-hover)',
    },
    ':active': {
      backgroundColor: 'var(--color-active)',
    },

    '> td': {
      lineHeight: '48px',
      ':first-of-type': {
        paddingLeft: '24px',
        textAlign: 'left',
      },
      ':last-child': {
        paddingRight: '24px',
      },
    },
  },
})
