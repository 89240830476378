/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { cache, use } from 'react'
import { css, jsx } from '@emotion/react'
import { useNavigate, useParams } from 'react-router-dom'
import countries from '@monorepo/countries/index.js'
import getLanguageNameByCode from '@monorepo/shared/getLanguageNameByCode.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import get from '../client/get.js'
import Layout from '../components/Layout.js'

let clickableCard = css([
  clickable,
  card,
  {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    marginRight: 16,
  },
])

export const paths = ['/users/:userIdOrEmail']

const fetchUserPageData = cache(userIdOrEmail =>
  get({ url: `/api/pages/users/${userIdOrEmail}` }),
)

const UserPage = () => {
  const navigate = useNavigate()
  const { userIdOrEmail } = useParams()
  let data = use(
    fetchUserPageData(userIdOrEmail).catch(_ => navigate('/users')),
  )
  // console.log(data)
  let { clinics, user, roles } = data || {}

  let title =
    `${user.firstName?.trim() || ''} ${user.lastName?.trim() || ''}`.trim() ||
    user.email ||
    user.phone ||
    user._id
  title = 'User ' + title
  const onClickTitle = () => {
    navigate('/users')
  }

  return (
    <Layout back title={title} main={false} onClickTitle={onClickTitle}>
      <div css={{ padding: 16 }}>
        <h1>User</h1>
        {user && (
          <>
            <div css={{ padding: 16 }}>
              email{' '}
              <a
                href={`mailto:${user.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {user.email}
              </a>
            </div>
            <div css={{ padding: 16 }}>
              phone{' '}
              <a
                href={`https://web.whatsapp.com/send?phone=${user.phone?.replace('+', '')}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {user.phone}
              </a>
            </div>
            <div css={{ padding: 16 }}>
              language {getLanguageNameByCode(user.language)}
            </div>
            <div css={{ padding: 16 }}>
              country {countries[user.country]?.countryName || user.country}
            </div>

            <div css={{ padding: 16 }}>
              <h2 css={{ paddingBottom: 8 }}>roles</h2>
              <div css={{ display: 'flex' }}>
                {roles.map(role => (
                  <div
                    key={role._id}
                    css={clickableCard}
                    onClick={() =>
                      navigate(`/organizations/${role.organizationId}`)
                    }
                  >
                    <div>{role.type}</div>
                    <div>organization {role.organizationId}</div>
                  </div>
                ))}
              </div>
            </div>
            <div css={{ padding: 16 }}>
              <h2 css={{ paddingBottom: 8 }}>clinics</h2>
              <div css={{ display: 'flex' }}>
                {clinics.map(clinic => (
                  <div
                    key={clinic._id}
                    css={clickableCard}
                    onClick={() => navigate(`/clinics/${clinic._id}`)}
                  >
                    <div>{clinic.name || clinic._id}</div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export default UserPage
