/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'

const input = css({
  boxShadow: 'inset 0px 0px 0px 1px #d8e0ed',
  borderRadius: 4,
  padding: 12,
  background: 'white',
  width: '100%',
  minWidth: 140,
  height: 46,
  '&::placeholder': {
    textTransform: 'capitalize',
  },
  '@media (hover: hover)': {
    '&:not(:disabled):hover': {
      boxShadow: 'inset 0px 0px 0px 2px var(--color-primary-lighter)',
      // TODO works but can't be passed directly a color
      // '&::-webkit-calendar-picker-indicator': {
      //   filter:
      //     'invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%)',
      // },
    },
  },
  '&:not(:disabled):focus-visible': {
    boxShadow: 'inset 0px 0px 0px 2px var(--color-primary-lightest)',
    cursor: 'text',
  },
  '&:not(:disabled):focus-visible-within': {
    boxShadow: 'inset 0px 0px 0px 2px var(--color-primary-lightest)',
    cursor: 'text',
  },
})

const Input = ({
  autoCapitalize,
  autoComplete,
  autoCorrect,
  autoFocus,
  disabled,
  label,
  min,
  minLength,
  name,
  onChange,
  placeholder,
  readOnly,
  required,
  type,
  value,
}) => {
  placeholder ||= label
  name ||= label

  return (
    <div css={{ paddingTop: 16 }}>
      <div
        css={{
          position: 'relative',
        }}
      >
        <label
          htmlFor={`input-${label}`}
          css={{
            fontSize: 12,
            background: 'white',
            position: 'absolute',
            top: '-7px',
            left: '8px',
            padding: '0 4px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            visibility: value ? 'inherit' : 'hidden',
          }}
        >
          {label}
        </label>
        <input
          autoCapitalize={autoCapitalize}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          autoFocus={autoFocus}
          css={input}
          disabled={disabled}
          id={`input-${label}`}
          min={min}
          minLength={minLength}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          readOnly={readOnly}
          required={required}
          type={type}
          value={value}
        />
      </div>
    </div>
  )
}

export default Input
